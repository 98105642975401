import "../styles/Login.css";
import "../App.css";
import logo from "../logo.svg";
import { useState } from "react";
import { logInWithEmailAndPassword } from "../configs/firebase-config";
import { Link } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className="Login">
      <div className="Login-body">
        <img src={logo} className="Login-logo" alt="logo" />
        <text className="Login-beta-text">BETA</text>
        <div className="Login-main">
          <h1>Login</h1>
          <div style={{ marginBottom: "20px" }} />
          <div className="Login-input">
            <text className="Login-input-label">Email</text>
            <input
              id="email"
              className="Login-input-box"
              type="email"
              maxlength="30"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="Login-input">
            <text className="Login-input-label">Password</text>
            <input
              id="password"
              className="Login-input-box"
              type="password"
              maxlength="30"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div style={{ marginBottom: "50px" }} />
          </div>
          <button
            className="Login-button"
            onClick={() => logInWithEmailAndPassword(email, password)}
          >
            Sign in
          </button>
          <button className="Login-button">Register</button>
          <Link to="/" draggable="false" className="Login-button">
            Back
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
