import "../App.css";
import "../styles/NoPage.css";
import logo from "../logo.svg";
import Header from "../components/Header";

function NoPage() {
  return (
    <div>
      <h1 className="Error404">404</h1>
      <h1>This page does not exist!</h1>
    </div>
  );
}

export default NoPage;
