import "./App.css";
import Layout from "./screens/Layout.js";
import Home from "./screens/Home.js";
import Profile from "./screens/Profile.js";
import Login from "./screens/Login.js";
import NoPage from "./screens/NoPage.js";
import Security from "./screens/Security.js";
import { StrictMode } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Profile />} />
            <Route path="profile" element={Profile()} />
            <Route exact path="security.txt" element={Security()} />
            <Route path="*" element={NoPage()} />
          </Route>
          <Route path="login" element={Login()} />
        </Routes>
      </BrowserRouter>
    </StrictMode>
  );
}

export default App;
