import "../App.css";

function Security() {
    return (
        <div>
            <p>Contact: TBD </p>
        </div>
    );
}

export default Security;