import "../styles/Profile.css";
import Header from "../components/Header";

function Profile() {
  return (
        <div className="App-main">
          <div className="App-main-header">
            <div className="App-profile-photo-div">
              <img className="App-profile-photo" />
            </div>
            <div className="App-main-header-right">
              <button className="App-button">Download</button>
            </div>
          </div>
          <div className="App-profile-name">
            <h1>Andrei Tudor Popescu</h1>
          </div>
          <h3>Honors & Computer Science and Engineering Student @ TU/e</h3>
          <h2>Skills:</h2>
        </div>
  );
}

export default Profile;
