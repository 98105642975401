import "../styles/Components.css";
import logo from "../logo.svg";
import { useState } from "react";
import { Link } from "react-router-dom";
import { user, logout } from "../configs/firebase-config.js";

function Header() {
  const [user, setUser] = useState(null);

  return (
    <header className="header">
      <img src={logo} className="App-logo" alt="logo" />
      <text className="App-beta-text">BETA</text>
      <div className="header-right">
        {user == null ? (
          <div>
            {/* <Link to="/login" draggable="false" className="App-button">
              Sign in
            </Link>
            <Link to="/register" draggable="false" className="App-button">
              Sign up
            </Link> */}
          </div>
        ) : (
          <button className="header-button" onClick={() => logout()}>
            Sign out
          </button>
        )}
      </div>
    </header>
  );
}

export default Header;
