import "../App.css";
import Header from "../components/Header";
import { Outlet } from "react-router-dom";

function Layout() {
  return (
    <div className="App">
      <Header />
      <div className="App-body">
        <Outlet />
      </div>
    </div>
  );
}

export default Layout;
