import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  signOut,
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  GithubAuthProvider,
} from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD3Q2g5pSXMmr555vsnG6lE2IF9beg0L9g",
  authDomain: "andreitudorp-efc40.firebaseapp.com",
  projectId: "andreitudorp-efc40",
  storageBucket: "andreitudorp-efc40.appspot.com",
  messagingSenderId: "998415249904",
  appId: "1:998415249904:web:133319311a9a5568fead35",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const githubProvider = new GithubAuthProvider();
export const auth = getAuth(app);
export const db = getFirestore(app);
export const user = auth.currentUser;

onAuthStateChanged(auth, (user) => {
  if (user) {
    user = auth.currentUser;
  } else {
    user = auth.currentUser;
  }
});

export const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export const logout = () => {
  signOut(auth);
};
